.userfeedback-wrapper table td{
    white-space: normal;
    word-wrap: break-word;
}
.userfeedback-wrapper .userfeedback-btn{
    color: #032f49;
    font-weight: bold;
    cursor: pointer;
}
.userfeedback-wrapper {
    overflow-y: auto;
}
.userfeedback-wrapper img{cursor: pointer;}
.userfeedback-wrapper th,td{
    padding: 18px;
}


.userfeedback-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
  .userfeedback-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #7A7C82; 
    border-radius: 10px;
  }
   
  .userfeedback-wrapper::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 10px;
  }
  
  .userfeedback-wrapper::-webkit-scrollbar-thumb:hover {
    background: gray; 
    cursor: pointer;
  }