.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    background-image: url("../../assets/imdexloginbg.png");
    background-size: cover; 
}

.signup-form {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 300px;
}

.signup-form h2 {
    text-align: center;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.signup-btn {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #28a745;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.signup-btn:hover {
    background-color: #218838;
}
.switchLogin{
    margin-top: 0;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    font-size: smaller;
    padding-top: 8px;
}
.switchLogin span{
    text-decoration: none;
    padding-left: 6px;
    color: #5bc0de;
}
.switchLogin span:hover{
    cursor: pointer;
    text-decoration: underline;
}
.signup-errormessage {
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    font-size: smaller;
    padding-top: 8px;
    color: red;
} 
@media screen and (max-width: 730px) {
    .signup-form{
        margin-top: 147px;
    } 
}