.header-wrapper{
    height: 30px;
    display: flex; 
    justify-content: end;
    align-items: center; 
    background-color: #fff; 
    margin-top: 10px;
}
.userEmail {
    display: flex;
    align-items: center;
    gap: 12px;
    
}
.userEmail span {
    font-size: 12px;
}
.logo-pallas {
    width: 133px;
    display: flex;
    align-items: center;
}
.logo-pallas img {
    width: 100%;
}
.profile-wrapper{
    position: relative;
    cursor: pointer;
}
.dropdown-content{
    display: none;
    position: absolute;
    border: 1px solid black;
    padding: 8px;
    border-radius: 4px;
    width: 100px;
    text-align: center;
    background-color: #000;
    color: #fff;
    font-weight: 500;
    right: 0;
}
.profile-wrapper:hover .dropdown-content {
    display: block;
    cursor: pointer; 
}
.settings-btn { 
    border: 1px solid #3E96D6;
    background-color: #F4FDFF;
    padding: 10px;
    border-radius: 6px;
}
.settings-btn:hover{
    cursor: pointer;
}
.logout-btn {
    display: flex;
    gap: 10px;
    padding-top: 3px;
}

.toggle-sidebar {
    display: none;
}
.toggle-sidebar:hover{
    cursor: pointer;
}
 
 .archived-chat-wrapper{
    display: flex; 
    gap: 4px;  
 }

 .archive-color{
    color:rgb(69 89 164);
 }
 .archive-delete-btns:hover{
cursor: pointer;
 }
 .back-btn-chat{
    cursor: pointer;
    margin-left: 50px;
 }
@media screen and (max-width: 730px) {
    .toggle-sidebar {
        display: block;
        position: absolute;
        left: 10px;
    }
  }
  .archivedChatIcon {
    display: flex;
  }