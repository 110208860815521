.main-page{ 
    display: flex;
    /* border: 1px solid violet;  */
}
.audits{
    display: flex;
}
/* .sidebar{
    width: 18%;
} */
.audit-content{
    width: 100%;
}
 