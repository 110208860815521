.focus-area-Wrapper{
    width: 80%;
    margin: auto;
    padding-top: 10px;
}
.focus-content-wrapper{
    margin: auto;
    width: 90%;
}
.focus-area-table-wrapper{
    width: 100%;
}
.Focus-area-heading{
    margin: 20px 20px; 
    text-align: center;
    color: #032F49;
}
.focus-filter-content{
    border: 1px solid black;
    height: 350px;  
    font-size: 12px;
    background-color: #4298CD;  
    color: #fff;
  }