.codingimprovements-content-wrapper{
  overflow: auto;
}
.coding-improvements-Wrapper {
  width: 80%;
  margin: auto;
  padding-top: 10px;
}
.hospital-revenue-findings {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  border-radius: 7px;
  background-color: #fff;
  font-weight: 600;
}
.Potential-revenue-uplift {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  border-radius: 7px;
  background-color: #fff;
  font-weight: 600;
} 
.codingimprovements-heading-wrapper{
  background-color: #fff;
  padding: 3px;
  margin-top: 5px;
}
.codingimprovements-heading {
  margin: 20px 20px; 
  text-align: center;
  color: #032F49;
}
.codingimprovements-filter-content {
  border: 1px solid black;
  height: 350px;
  font-size: 12px;
  background-color: #4298CD;
  color: #fff;
  overflow-x: overlay;
}
.HeatMapgreen{
  background-color: #B5E5D0;
}
.HeatMapyellow{
  background-color: #F2D97E;
}
.HeatMaporange{
  background-color: #F2A683;
}
