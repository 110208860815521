.audit-details {
  width: 85%; 
  margin: auto;
}
.audit-details h5 {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.audit-main{
  border:1px solid #EAEAEA;
  border-radius: 5px;
  
}
.folder-image{ 
  border: 1px solid #E6E6E6;
  border-radius: 50%;
  object-fit: cover; 
  padding: 5px;
}
.webworkflow-crumb-wrapper{
  margin-top: 10px;
  margin-bottom: 10px;
}
.workflow-bread-crumb-first{
  color: #032F49;
}
.workflow-bread-crumb-first:hover{
  cursor: pointer;
}
.workflow-bread-crumb-second{
  color: #475467;
}
.audit-deatils-header-text{
  color: #032F49;
}
.audit-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1px;
  padding:10px 2px;
  padding-left: 20px;
  font-size: small;
  background-color: #fff;
  padding-right: 20px;
}

.status-container-values-wrapper {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  border-top: 1px solid grey;
}
 
.audit-details-header > p {
  margin: 0;
  position: relative;
  padding: 6px;
  font-weight: 500;
}
.audit-details-header > p:after{
  content: "";
  border-right: 2px solid #9E9E9E;
  position: absolute;
  right: 0px;
  height: 12px;
  top: 12px;

}
p.addmission-date,
p.seperation-date {
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 12px;
}
.addmission-date { 
  border-radius: 2px;
  padding: 2px;
}
.seperation-date { 
  border-radius: 2px;
  padding: 2px;
}

.folder-icon-container{
  position: relative;
}
.folder-icon-container:after{
  content: "";
    border-right: 2px solid #9E9E9E;
    position: absolute;
    right: 0px;
    height: 12px;
    top: 12px;
}
.folder-icon-container span{
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.folder-icon-container svg {
  width: 15px;
  height: 15px;
}
.status-container select {
  color: #606770;
  outline: none;
  border: none;
  border-radius: 5px;
  border:1px solid #606770;
}
.status-container {
  color: #606770;
  text-align: left;
  background-color: #fff;
  padding: 7px 0px;
  padding-left: 20px;
}
.status-container-values {
  display: flex;
  font-size: 13px;
  color: grey;
  padding-top: 4px;
  justify-content: space-between;
  padding: 10px 1px;
  align-items: center;
}
.status-container-value-spacing {
  padding-left: 20px;
}

.status-container-values div {
  gap: 0;
  font-size: 12px;
  margin-right: 5px;
}
.insurer-identifier {
  border-radius: 9px;
  background-color: #606770;
  padding: 3px;
  color: #fff;
  display: inline-block;
}
.charge-discription {
  font-size: small;
  color: gray;
  text-align: justify;
}
.charge-discription-wrapper {
  display: flex;
  gap: 20px;
  margin-right: 7px;
  align-items: center;
}
.missing-link {
  font-size: small;
  color: #3EBAD6;
  border-bottom: 1px solid #eaeffe;
  padding: 3px;
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.missing-link img{
  margin-right: 10px;
}
.audit-description {
  display: flex;
  border-bottom: 1px solid #EAEAEA;
}
.audit-description-left {
  width: 50%;
  font-size: 12px;
  text-align: left;
}
.audit-description-right {
  width: 50%;
  font-size: 12px;
  text-align: left;
}
.row-name {
  color: #5e5e5f;
  font-weight: bold;
}
.row-value {
  color: #5e5e5f;
  font-weight: bold;
  margin-bottom: 10px;
}

.toggle-next-prev {
  display: flex;
  justify-content: space-between;
  padding: 7px 0px;
}
.previous-next-wrapper {
  display: flex;
  gap: 8px;
  margin-right: 25px;
  font-family: sans-serif;
}
.previous-btn {
  border: none;
  padding: 9px 16px;
  border-radius: 6px;
  color: #606770;
  background-color: #fff;
  border:1px solid #EAF0F6;
}
.previous-btn:hover {
  cursor: pointer;
}
.next-btn {
  border: none;
  padding: 9px 16px;
  border-radius: 6px;
  color: #606770;
  background-color: #fff;
  border:1px solid #EAF0F6;
}
.next-btn:hover {
  cursor: pointer;
}
.donot-flag { 
  border-radius: 8px;
  color: #FFF;
  background-color: #4298CD;
  font-size: 14px;
  width: fit-content;
  padding: 2px 10px;
}
.donot-flag:hover{
  cursor: pointer;
}
.type-info-wrapper{
  margin-top: 10px;
}
.comments-text-wrapper label{
  font-size: 12px;
}
.type-info-wrapper label{
  padding-bottom: 5px;
  font-size: 12px;
}
.type-info {
  width: 75%;
  padding: 10px;
  font-size: 12px;
  color: #606770;
  border: 1px solid #ded8d8;
  border-radius: 5px;
} 

/* edit audit Details modal */
.editAuditDetails-btn {
  cursor: pointer;
}
.auditrule-discription-modal {
  text-align: left;
  margin-bottom: 8px;
}
.auditrule-discription-modal input {
  width: 100%;
  font-size: small;
}
.scroll-wrapper {
  width: 30%;
}
.view-all-codes-btn {
  border: 1px solid grey;
  border-radius: 5px;
}
.procedure-codes-wrapper,
.additional-diagnoses-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.procedure-code-field,
.additional-diagnoses-field {
  width: 50%;
}
.procedure-codes-wrapper{
  font-size: 12px;
  text-align: left;
}
.additional-diagnoses-wrapper{
  font-size: 12px;
  text-align: left;
}
.audit-description-left-input,
.audit-description-right-input{
  width: 80% ;
}
.procedure-code-field-input,
.additional-diagnoses-field-input{
  width: 80%;
} 

.generate-cdq-btn{
  border: none;
  background-color: #4298CD;
  border-radius: 6px;
  color: #fff;
}
.generate-cdq-btn:hover{
  background-color: #2a9fe7;
}
.audit-comment-btn{
  border: none;
  background-color: #4298CD;
  border-radius: 6px;
  color: #fff;
}
.audit-comment-btn:hover{
  background-color: #2a9fe7;
}
.generatecdqmodal ul{
list-style: none;
}
.generatecdqmodal ul li{
  color: green;
  margin-bottom:15px;
}
.cdq-header-text{
  color: #032F49;  
}