

/* .Auditruledetailsheader h2{
    font-size: 30px;
    margin-top:300px ;
} */
.Auditrulelogo{
    max-width: 120px;
    width: 100%;
    position: absolute;
    left: 20px;
    top: 21px;
}