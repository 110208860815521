.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    background-image: url("../../assets/imdexloginbg.png");
    background-size: cover; 
}

.login-form {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 300px;
}

.login-form h2 {
    text-align: center;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.Login-btn {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.Login-btn:hover {
    background-color: #0056b3;
}
.switchSignUp{
    margin-top: 0;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    font-size: smaller;
    padding-top: 2px;
}
.switchSignUp span {
    text-decoration: none;
    padding-left: 6px;
    color: #5bc0de;
}
.switchSignUp span:hover {
    cursor: pointer;
    text-decoration: underline;
}
.forgotPassword{
    display: flex;
    justify-content: center;
    color: #ffbf00;
}
.forgotPassword:hover{
    color: #c49508;
    cursor: pointer;
    text-decoration: underline;
}
.login-errorMessage {
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    font-size: smaller;
    padding-top: 8px;
    color: red;
} 

@media screen and (max-width: 730px) {
    .login-form {
        margin-top: 60px;
    } 
}
