@font-face {
  font-family: "FoundersGrotesk";
  src: local("FoundersGrotesk"),
    url("./assets/fonts/FoundersGrotesk-Light.otf") format("truetype");
}

.primaryAuthBg {
  background-color: #fff;
}
.addicon-files{
  color: #fff;
  height: 20px;
  width: 20px;
}

.primaryButton {
  background-color: #032F49 !important;
  border: none !important;
  outline: none !important;
  color: #fff !important;
}

.is-invalid {
  border-color: #ff284d !important;
  border-width: 2px !important;
}

.primaryAuthSideBg {
  background-image: url("./assets/jpg/LoginBg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
}

.invalid-feedback {
  font-size: 12px !important;
  font-weight: 600;
  color: #ff284d !important;
}

.danger {
  color: #ff284d !important;
  font-weight: 500;
}

.dangerBorder {
  border: 1px solid #ff284d;
  background-color: #fffafa;
}

.profilePic {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgb(63, 60, 60);
}

.logoSyneo {
  width: 3rem;
  height: 3rem;
  background-color: white;
}

.h-10 {
  height: 10%;
}
.h-20 {
  height: 20%;
}
.blueText {
  color: #738dec;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.selectedTab {
  border: 2px solid #4298CD;
}
.selectPeriod {
  border: 2px solid #4298CD;
  border-radius: 13px;
}
.periodText {
  color: #738dec;
}
.progressSuccess {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: #e5e9f6;
}
.progressError {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: #ffefeb;
}
a {
  text-decoration: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.f-700 {
  font-weight: 700;
}
.logOutPop :hover {
  background-color:rgba(0, 0, 0, 0.158);
}
@media (max-width: 575.98px) {
  .uploadTab {
    width: 120px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .uploadTab {
    width: 160px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .uploadTab {
    width: 130px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .uploadTab {
    width: 125px;
  }
}

@media (min-width: 1200px) {
  .uploadTab {
    width: 155px;
  }
}

@media (min-width: 1300px) {
  .uploadTab {
    width: 165px !important;
  }
}
@media (min-width: 1400px) {
  .uploadTab {
    width: 185px !important;
  }
}
