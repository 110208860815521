
  
  /* Table wrapper */
  .auditrule-table-wrapper::-webkit-scrollbar {
    width: 8px;
  }
  .auditrule-table-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
  
  .auditrule-table-wrapper::-webkit-scrollbar-track {
    height:100px;
    background: lightgrey;
  }
  .auditrule-table-wrapper {
    background-color:  #ffffff;
    padding: 10px;
    border-radius: 2px;
    overflow-x: auto;
    margin-bottom: 40px;
    overflow-y: auto; 
}

.auditrule-table-wrapper table {
    width: 100%;                                        
    margin-bottom: 50px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    
    
}
.auditrule-table-wrapper tr:hover {
  background-color: #1f2faa; /* Color when hovering over a table row */
}
.auditrule-table-wrapper tr {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
}

.auditrule-table-wrapper th,
.auditrule-table-wrapper td {
    
    padding: 8px;
    border: 1px solid #dee2e6;
    text-align: left;
    text-overflow: unset;
    overflow: unset;
    white-space: inherit;
    font-size: 14px;
    
}

.auditrule-table-wrapper th {
    border-left: none; 
    border-right: none;
    /* border: 1px solid #dee2e6; */
    background-color: #f8f9fa;
    position: sticky;
    top:-10px;
    z-index: 10; 
    font-weight: normal;
    color:#65859c;
     
}
.auditrule-table-wrapper thead {
  z-index: 11; /* Higher than th */
  position: sticky;
  top: -10px;
  background-color: #f8f9fa;
}

/* Ensure the checkbox appears as a square and has a custom checkmark */
.checkbox-wrapper {
  position: relative;
  
}

.checkbox-wrapper input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid grey;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  outline: none;
}

.checkbox-wrapper input[type="checkbox"]:checked::after {
  content: '\2713'; /* Unicode checkmark character */
  color: black; /* Change to the desired color */
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-wrapper input[type="checkbox"]:checked {
  background-color: white; 
  border: 1px solid #ccc;
}


.auditrule-table-wrapper td {
    border-left: none; 
    border-right: none;
    /* border: 1px solid #dee2e6; */
}

.auditrule-table-wrapper tr:nth-child(even) {
    background-color: #f8f9fa;
}

.auditrule-table-wrapper tr:hover {
    background-color: #e9ecef;
    cursor: pointer;
}

.add-new-category-icon {
    display: flex;
    align-items: center;
}

.add-new-category-icon:hover {
    cursor: pointer;
}

.refresh-icon:hover {
    cursor: pointer;
}

.audit-rule-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-right: 10px;
}

.audit-rule-header h2 {
  margin: 0;
}

.right-side {
  display: flex;
  /* align-items: center; */
}

.search-bar {
  display: flex;
  /* align-items: center; */
  margin-right: 16px;
}

.search-bar .search-icon {
  margin-right: 8px;
}

.search-bar input {
  /* padding: 5px 10px; */
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  padding-left :30px;
}

.create-audit-rule .btn-create-audit-rule {
  background-color: #56a0d3;
  
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.btn-add-category{
  background-color: #56a0d3;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
} 
.update-audit-rule{
  background-color: #56a0d3;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.h3-style{
  font-size: small;
  margin-left: 10px;
  color: #65859c;
}
.plus-sign {
  font-size: 20px; /* Adjust size as needed */
  /* font-weight: bold; */
}

/* Custom modal styles */
.custom-modal .modal-dialog {
  max-width: 600px;
}

.custom-modal .modal-content {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.custom-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.custom-modal .modal-title {
  font-size: 24px;
  font-weight: bold;
}

.custom-modal .modal-body {
  padding-top: 10px;
}

.custom-modal .modal-footer {
  border-top: none;
  padding-top: 20px;
  /* justify-content: space-between; Spread the buttons horizontally */
}

.custom-modal .form-group {
  margin-bottom: 20px;
  position: relative;
}

.custom-modal .form-label {
  position: absolute;
  top: -12px;
  left: 15px;
  background: white;
  padding: 0 5px;
  font-size: 14px;
  color: #6c757d;
  
}

.custom-modal .form-control {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 10px 15px;
  font-size: 16px;
}

.custom-modal .form-row {
  display: flex;
  justify-content: space-between;
}

.custom-modal .form-row .form-group {
  flex: 1;
  margin-right: 10px;
}

.custom-modal .form-row .form-group:last-child {
  margin-right: 0;
}

.custom-modal .btn {
  width: 24%; 
  border-radius: 8px;
}

.custom-modal .btn-secondary {
  background-color: #ffffff;
  border: 2px solid #FF8C00;
  /* border-radius:5px; */
  color: #FF8C00;
}
.custom-modal .btn-primary {
  background-color: #0a2139;
  border: none;
}
.custom-modal hr {
  margin: 40px 0 0;
  border: none;
  border-top: 2px solid #343a40; /* Darker line */
}
.modal-backdrop.show {
  opacity: 0.7;
}



.custom-modal .form-group .caret-icon {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 30%;
  pointer-events: none;
}

.expanded-row {
  background-color: #f2f5f6;
}

.expanded-row td {
  border-top: none;
  background-color: #f2f5f6;
}

.expanded-content {
  padding: 15px;
  justify-content: space-around;
}

.expanded-info {
  display: flex;
  flex-direction: row;
  padding: 10px;
  
}

.long-description {
  flex: 1;
  margin-right: 10px;
  border-radius: 8px;
  background-color: #e0e7e9;
  padding: 10px;
  border: 2px solid #E6EDF4;
  margin-bottom: 10px;
}

.user-message {
  flex: 1;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #e0e7e9;
  padding: 10px;
  border: 2px solid #E6EDF4;
  margin-bottom: 10px;
}

.edit-button {
  margin-top: 30px;
  display: inline-flex;
  align-self: flex-end;
  justify-content: center;
  background-color: #56a0d3;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.edit-button .ml-1 {
  margin-left: 8px;
  margin-top :5px; /* Space between the text and the icon */
}

.edit-button .faPencilAlt {
  font-size: 16px; /* Adjust the icon size if necessary */
}

.no-border {
  border-bottom: none;
}
.expanded td{
  background-color: #f2f5f6;
  border-bottom: none;
}
.edit {
  text-align: end;
  margin-right: 20px;
}

.longdescription-text-color,
.user-text-color {
  color: #65859c;
  font-weight: normal;
}