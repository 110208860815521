.viewdashboard-btn{
    color: #fff;
}
.viewdashboard-btn:hover{
    cursor: pointer;
}
.DiscoveryCompanionLogo-wrapper{
    display: contents;
}
.section-wrapper{
    display: flex;
    align-items: center;
}
.view-dashboard-path{
    color: blue;
    text-decoration: underline;
    margin-bottom: 12px;
}
.view-dashboard-path:hover{
cursor: pointer;
}