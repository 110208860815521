.cerebrumNav {
  margin-top: 10px;
    background: #fff
}
.cerebrum-nav-wrapper{
    width: 70%;
    margin: auto;
}
.files-dropdown{
    display: flex;
    margin-left: 20%;
}
.toggle-btns span{
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    color: black;
    border-radius: 6px;
    background-color: #F3F3F5;
}
.toggle-btns span.active {
    /* border-bottom: 3px solid #f29020;  */
    color: #fff;
    background-color: #032F49;
  }
  /* .tab-content-wrapper{
    background-color: #f3f3f3;
  }  */
  .select-hcp-files{
    color: #000;
  }
  .hcp-dropdown-menu{
    max-height: 300px;
    overflow-y: auto; 
    overflow-x: hidden;
  }
  .hcp-file-dropdown-powerbi .dropdown-toggle {
    background-color: #F4FDFF;
    color: #4298CD;
    border: 1px solid #3E96D6;
    margin-left: 10px
  }
  
  .hcp-file-dropdown-powerbi .dropdown-toggle:focus,
  .hcp-file-dropdown-powerbi .dropdown-toggle:hover {
    background-color: #F4FDFF; 
    color: #4298CD;
  }
  .hcp-file-dropdown-powerbi .dropdown-menu {
    background-color: #F4FDFF !important;
    color: #4298CD !important;
    padding-left: 8px;
  }
  
  .hcp-file-dropdown-powerbi .dropdown-menu .form-check-label {
    color: #4298CD !important;
  }
