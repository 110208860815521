.main-page-container { 
  display: flex; 
  height: calc(100% - 80px);
  width: 100%;
}

.dark-container .intial-message-card-wrapper{
  height: calc(100% - 140px);
}
.page-wrapper { 
  padding: 0 40px; 
  left: 194px;
  top: 50px;
  bottom: 0;
  right: 0; 
  overflow: auto;
  width: 100%;
}
.chat-space-wrapper {
  display: flex;
  align-items: center;
  padding: 13px 0 10px;
  position: relative;
}
.coder-toggle-button {
  background-color: #fff;
  border-radius: 12px;
}
.coder-toggle-button:hover {
  cursor: pointer;
}
.coder-toggle-button span {
  width: 137px;
  height: 39px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  color: #1c1813;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #eaf0f6;
  border-radius: 8px;
}
.coder-toggle-button span.active {
  background-color: #4298cd;
  border-radius: 9px;
  color: #fff;
}
.gptLogo {
  position: absolute;
  right: 0;
  top: calc(50% - 23px);
}
.code-chat {
  width: 100%;
  height: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.code-chat > div p {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
}
.chatText {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 6px;
  flex-wrap: wrap;
  gap: 8px;
  text-align: left;
}
.chatText > div {
  width: calc(50% - 8px);
  background-color: #fff;
  border-radius: 12px;
  padding: 7px 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: 1px solid #3ebad6;
  border-radius: 8px;
}

.chatText > div p {
  margin: 0;
  color: #343541;
  font-weight: 400;
  font-size: 14px;
}
.chatText > div span {
  color: #032f49;
  font-weight: bold;
  font-size: 14px;
}
/* .chatText >div >div {
    display: none;
} */

.chatText > div img {
  display: none;
}
.chatText > div > div img {
  width: 100%;
}
.chatText > div:hover img {
  display: block;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.search-wrapper {
  margin: 12px 8px 12px 8px;
  padding-bottom: 14px;
  position: relative;
  display: flex;
  background-color: #fff;
  height: 35px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 300;
  justify-content: space-between;
  padding: 7px 20px;
  gap: 5px;
  color: #6c6c6c;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.search-wrapper input {
  width: 100%;
  border: none;
  padding-left: 14px;
  outline: none;
}
.search-wrapper .input-button {
  top: calc(50% - 21px);
  right: 6px;
  width: 20px;
  height: 20px;
}
.search-wrapper .input-button:hover {
  cursor: pointer;
}

.search-wrapper > div img {
  width: 100%;
  height: 100%;
}

/* messages design */
.message-wrapper {
  overflow-y: scroll;
  overflow-x: hidden; 
  height: 100%;
} 
.initial-messages{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.intial-message-card-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  justify-content: space-between;
}
.message-wrapper::-webkit-scrollbar {
  width: 0; /* Hide scrollbar */
}
.my-input-message {
  display: flex;
  background-color: #fff;
  padding: 10px 15px;
  gap: 10px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 14px;
}
.my-input-logo p {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: purple;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.my-input-logo img {
  width: 35px;
  height: 35px;
  margin-top: 8px;
}
.my-input-message-data {
  font-size: 14px;
  width: 100%;
  text-align: justify;
}
.my-input-message-data span {
  font-weight: bolder;
  margin-bottom: 5px;
}
.my-input-message-data p {
  margin: 0px;
}
.like-dislike-icon {
  margin-right: 12px;
}
.selected {
  color: #00f;
}
.like-dislike-icon:hover {
  cursor: pointer;
}

/* stop generating */
.stop-generating {
  border: 1px solid black;
  width: 133px;
  background-color: #032f49;
  color: #fff;
  border-radius: 6px;
  font-size: small;
  display: flex;
  padding: 4px;
  gap: 10px;
  position: absolute;
  top: -35px;
  cursor: pointer;
  margin: 0;
}

.recommendation-modal .modal-content {
  min-height: 400px;
}
.recommendation-modal .loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
}
.recommendation-modal .modal-footer,
.recommendation-modal .modal-body {
  padding: 5px;
}
.recommendation-modal .modal-body {
  max-height: 500px;
  overflow: auto;
}
.recommendation-modal > div {
  max-width: 900px;
  padding: 10px;
}
.reference-content {
  margin-bottom: 3px;
}
.references-wrapper {
  border: 1px solid black;
  padding: 14px;
  margin: 15px;
  word-break: break-all;
}
.citations-button {
  color: #1570ef;
  border: 1px solid black;
  border-radius: 2px;
  background-color: #fff;
  margin-left: 4px;
  font-size: 10px;
}
.citations-button:hover {
  color: #fff;
  background-color: #1570ef;
}
.references-color {
  color: green;
}
.references-btn-version1{
  border: none;
  padding: 8px;
  color: white;
  background-color: #7daef3;
  border-radius: 6px;
}
.references-btn-version1:hover{
  background-color: #5f9ef7;
}
.light-sidebar .sidebar-scroll {
  max-height: 600px;
  overflow: auto;
}
@media screen and (max-width: 730px) {
  .chat-space-wrapper {
    display: block;
    text-align: center;
    position: initial;
  }
  .page-wrapper {
    left: 0;
  }
  .coder-toggle-button {
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: fit-content;
  }
  .gptLogo {
    position: initial;
  }
  .main-page-container {
    margin-left: 0px;
  }
  .chatText > div {
    width: 100%;
  }
}
