.multi-select-dropdown {
    position: relative;
    width: 200px; /* Adjust width as needed */
  }
  
  .multi-select-dropdown .dropdown-menu {
    max-height: 200px; /* Adjust height as needed */
    overflow-y: auto;
    width: 100%;
  }
  
  .multi-select-dropdown .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
  
  .multi-select-dropdown .dropdown-item .form-check-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }