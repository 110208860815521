.forgot-password-container-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    background-image: url("../../assets/imdexloginbg.png");
    background-size: cover; 
}
.forgot-password-container {
    max-width: 400px;
    max-height: 400px;
    margin: 100px auto; /* Center vertically with some margin from the top */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center; 
  }
  
  .forgot-password-container h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .btn-warning {
    background-color: #f0ad4e;
    color: #fff;
  }
  
  .btn-warning:hover {
    background-color: #ec971f;
  }
  
  .error-message {
    color: #d9534f;
    font-size: 0.875em;
    margin-top: 10px;
  }
  
  .success-message {
    color: #5bc0de;
    font-size: 0.875em;
    margin-top: 10px;
  }
  
  .forgotswitchLogin {
    color: #5bc0de;
    font-size: 0.875em;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .forgotswitchLogin:hover {
    text-decoration: underline;
  }