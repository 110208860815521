 
.landingpage-wrapper-main{
    background-color: white;
    text-align: justify;
    margin-top: 12px;
}
.main-landing-page1 {
    width: 100%;
    max-width: 1124px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border: 1px solid;
    border-radius: 8px;
    background-image: url('/src/pages//landingPage/assets/landingbackground.png'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top:5px;
  
}
.main-landing-page1{
    cursor: pointer;
}
.main-landing-page1 .left-pagecoder{
    color: #fff;
}
.left-pagecoder {
    width: 50%;    
    padding: 35px 0px 0px 0px;
    text-align: left;
}
.left-pagecoder h2 { 
    font-family: "PlusJakartaSans";
    font-size: 38px;
    font-weight: 700;
}
.left-pagecoder p { 
    font-family: "PlusJakartaSans";
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px; 
}
.left-pagecoder img {
    margin-top: 30px;
}
.right-pagecoder {
    width: 49%;
    padding: 37px;
    border: 1px solid;
    background-color: white;
    border-radius: 7px;
    background-color: #F9F7F7;
    text-align: left;
}
.right-pagecoder p {
    font-family: "PlusJakartaSans"; 
    font-size: 18px;
    font-weight: 700;
    text-align: left;
}
.right-pagecoder ul {
    font-family: "PlusJakartaSans"; 
    font-size: 14px;
    font-weight: 400;
    color: #405169;
}
.internal-page{
    display: flex; 
}

.internal-page button {
    margin-left: 25px;
    padding: 5px 13px;
    background-color: #cbcbcb;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 10px;
}
.main-landing-page3 .internal-page button{
    background-color: #344054;
}
.internal-page button.button-demo {
    background-color: white;
    border: 1px solid gray;
    margin: 0px;
    color: black;
    display: inline-flex;
    align-items: center;
    gap: 2px;
    margin-left: 25px;
}
.main-landing-page2 {
    width: 100%;
    max-width: 1124px;
    margin: 5px auto;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-radius: 8px;
    background: rgb(194,245,255);
    background: linear-gradient(276deg, rgba(236, 247, 252) 60%, rgba(244, 240, 246) 100%);
}
.main-landing-page2:hover{
    cursor: pointer;
}
.main-landing-page3 {
    /* width: 100%;
    max-width: 1124px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    background-color: white; 
    border-radius: 8px; 
    overflow: hidden;
    border: 7px solid;
    border-image: linear-gradient(to right, #D9FEEC, #DAF0FE, #E7D9FE, #FEE1D9, #FBFEE2) 1;
    border-image-slice: 1; */
    width: 100%;
    max-width: 1124px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-radius: 8px;
    background: rgb(194,245,255);
    background: linear-gradient(276deg, rgba(239, 245, 246) 60%, rgba(254, 249, 246) 100%);
}
 
.main-landing-page3{
    cursor: pointer;
}
.main-landing-page .internal-page button {
    background-color:black;
    color: white;
    margin-left: 25px;
    padding: 0px 39px;
    border-radius: 5px;
}
.main-landing-page .internal-page button.button-demo {
    background-color: white;
    border: 1px solid gray;
    margin: 0px;
    color: black;
    display: inline-flex;
    align-items: center;
    gap: 2px;
    margin-left: 25px;
}
.main-landing-page2 .internal-page button {
    background-color:#344054; 
}
.main-landing-page2 .internal-page button.button-demo {
    background-color: white;
    border: 1px solid gray;
    margin: 0px;
    color: black;
    display: inline-flex;
    align-items: center;
    margin-left: 25px;
    gap: 2px;
} 
@media only screen and (max-width: 730px) {
    .main-landing-page {
        display: block;
    }
    .left-pagecoder {
        width: 100%;
    }
    .right-pagecoder {
        width: 100%;
    }
    .main-landing-page2 {
        flex-direction: column;
        gap: 20px;
    }
    .main-landing-page1 {
        flex-direction: column;
        gap: 20px;
    } 
    .main-landing-page3{
        flex-direction: column;
        gap: 20px;
    }
}
@media only screen and (min-width: 731px) and (max-width: 1088px){
    .internal-page{
        flex-wrap: wrap;
        gap: 20px;
    }
    .internal-page button {
        width: 100%;
    }
}
@media only screen and (max-width: 428px){
    .internal-page{
        flex-wrap: wrap;
        gap: 20px;
    }
    .internal-page button {
        width: 100%;
    }
}