.finding-wrapper {
  width: 80%;
  margin: auto;
  padding-top: 10px;
}

.top-calculations {
  display: flex;
  border: 1px solid #eaeaea;
  border-radius: 7px; 
}
.potential-rev {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  border-radius: 7px;
  background-color: #fff;
  font-weight: 600;
}
.episodes-Flagged {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  border-radius: 7px;
  background-color: #fff;
  font-weight: 600;
}
.percentage-total-flagged-cases {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  border-radius: 7px;
  background-color: #fff;
  font-weight: 600;
}
.finding-heading {
  margin: 20px 20px;
  text-align: center;
  color: #032f49;
}
.table-container {
  width: 100%;
  /* height: 400px; */
  height: 80vh;
  overflow-y: auto;
  border: 1px solid #ddd;
  background-color: #fff;
  margin-top: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px; /* Add more padding for better spacing */
  text-align: left;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Prevent word breaks */
  text-overflow: ellipsis;
  overflow: hidden;
}

th {
  background-color: #f2f2f2;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 1;
}

tr:hover {
  background-color: #f5f5f5;
}

th,
td {
  max-width: 200px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.findings-filter-content {
  border: 1px solid black;
  height: 400px;
  font-size: 7px;
  background-color:  #4298CD;
  color: #fff;
}
